import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-odessa-texas.png'
import image0 from "../../images/cities/scale-model-of-jack-rabbit-in-odessa-texas.png"
import image1 from "../../images/cities/scale-model-of-stonehenge-replica-in-odessa-texas.png"
import image2 from "../../images/cities/scale-model-of-classic-group-tours-in-odessa-texas.png"
import image3 from "../../images/cities/scale-model-of-odessa-convention-&-visitors-bureau-in-odessa-texas.png"
import image4 from "../../images/cities/scale-model-of-any-seasons-travel-in-odessa-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Odessa'
            state='Texas'
            image={image}
            lat='31.8456816'
            lon='-102.36764310000001'
            attractions={ [{"name": "Jack Rabbit", "vicinity": "4989-4999 E 42nd St, Odessa", "types": ["point_of_interest", "establishment"], "lat": 31.89363689999999, "lng": -102.33484320000002}, {"name": "Stonehenge Replica", "vicinity": "Preston Smith Rd, Odessa", "types": ["park", "point_of_interest", "establishment"], "lat": 31.8919435, "lng": -102.32625510000003}, {"name": "Classic Group Tours", "vicinity": "2817 John Ben Shepperd Pkwy #c, Odessa", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 31.882726, "lng": -102.331098}, {"name": "Odessa Convention & Visitors Bureau", "vicinity": "700 N Grant Ave, Odessa", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 31.8507008, "lng": -102.37101619999999}, {"name": "Any Seasons Travel", "vicinity": "3952 E 42nd St Suite E, Odessa", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 31.891478, "lng": -102.34330899999998}] }
            attractionImages={ {"Jack Rabbit":image0,"Stonehenge Replica":image1,"Classic Group Tours":image2,"Odessa Convention & Visitors Bureau":image3,"Any Seasons Travel":image4,} }
       />);
  }
}